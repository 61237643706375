import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import budgetGroupImage from './images/BudgetGroups.png'
import accountBalanceImage from './images/AccountBalance.png'
import todoScreen from './images/TodoTasks.png'
import addTask from './images/Add a task.png'
import agenda from './images/Agenda.png'
import shoppingList from './images/Shoppinglistexample.png'
import savedItems from './images/SavedItems.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
  };
  return (
    <Router>
    <div className="App">
      <header className="App-header">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h1>theMuffin</h1>
          </Link>      </header>
      <main>
      <Routes>
      <Route path="/" element={
              <>
        <section className="about-section">
          <h2>About</h2>
          <p>
            theMuffin app is designed to streamline your digital life by combining several essential features into one convenient app. With theMuffin, you can manage your personal budgeting, todo tasks, checklists, and shopping lists all in one place.
          </p>
        </section>
        <div className="bottom-section">
          <div className="scrollable-list">
            <ul>
              <li><strong>Household:</strong> Invite your household members to your account.</li>
              <li><strong>Personal Budgeting:</strong> Keep track of your finances with ease.</li>
              <li><strong>Account Balance:</strong> Track your remaining budget overall and for different groups.</li>
              <li><strong>Todo Tasks:</strong> Organize your tasks, get reminders and stay on top of your schedule.</li>
              <li><strong>Checklists:</strong> Create and manage checklists for any purpose.</li>
              <li><strong>Shopping Lists:</strong> Simplify your shopping experience with organized lists.</li>
            </ul>
          </div>
          <div className="image-container">
            <Slider {...settings}>
              <div>
                <img src={budgetGroupImage} alt="Budget Groups" />
              </div>
              <div>
                <img src={accountBalanceImage} alt="Account Balance" />
              </div>
              <div>
                <img src={todoScreen} alt="Todo Lists" />
              </div>
              <div>
                <img src={addTask} alt="Add a task" />
              </div>
              <div>
                <img src={agenda} alt="Agenda" />
              </div>
              <div>
                <img src={shoppingList} alt="Shopping Lists" />
              </div>
              <div>
                <img src={savedItems} alt="Saved Items" />
              </div>
              </Slider>
          </div>
          </div>
          </>
            } />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
      </main>
      <footer className="App-footer">
        <p>&copy; 2024, MuffinLabs Inc.</p>
        <p>
        <a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="mailto:info@themuffin.com">info@themuffin.com</a>
        </p>
      </footer>
    </div>
    </Router>

  );
}

export default App;
